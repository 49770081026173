import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui';
router.afterEach((to) => {
  if (typeof _hmt !== 'undefined') {
    _hmt.push(['_trackPageview', to.fullPath]);
  }
});

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
