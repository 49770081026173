import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const HomePage = () => import('@/view/home/index.vue');
const detail = () => import('@/view/detail/index.vue');
const jiaotong = () => import('@/view/detail/jiaotong.vue');
const yiliao = () => import('@/view/detail/yiliao.vue');
const qinzhi = () => import('@/view/detail/qinzhi.vue');
export default new VueRouter({
  routes: [
    {
      path: '/',
      redirect: 'home',
      component: HomePage,
      children: [
        {
          path: 'home',
          component: HomePage,
          name: '首页',
        }
      ]
    },
    {
      path: '/detail',
      component: detail
    },
    {
      path: '/jiaotong',
      component: jiaotong
    },
    {
      path: '/yiliao',
      component: yiliao
    },
    {
      path: '/qinzhi',
      component: qinzhi
    }
  ]
})