// element.js 全文内容如下，按自己需要引入就好了
import Vue from 'vue'
import {
    Form,
    FormItem,
    Message,
    MessageBox,
	Notification,
	Loading
} from 'element-ui'
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Loading)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm